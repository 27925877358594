.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.example-input .ant-input {
  width: auto;
  min-width: 50px;
  margin: 0 0 20px 20px;
}

.ant-btn-loading-icon {
  display: inline-flex !important;
  align-items: center !important;
}
