.label {
    font-size: 20px;
    font-weight: bold;
    display: flex !important;
    align-items: center !important;
    /* justify-content: flex-start !important; */
}

.editBuyerIcon {
    margin-left: 16px !important;
    cursor: pointer !important;
}

.attendantName {
    font-size: 120%;
    font-weight: bold;
    color: #1890ff;
}

.infoContainer {
    font-size: 16px;
    margin: 0 0 0 0;
    padding: 0 0 15px 0;
}

.buyerLabel {
    font-weight: 600;
}

.editInput {
    outline: 0;
    border-width: 0 0 1px;
    border-color: #1890ff;
    margin-left: 8px;
}

.orderCode {
    font-size: 150%;
    font-weight: bold;
    color: #1890ff;
    margin: 0 0 15px 0;
}

.backgroundTr {
    background-color: #f5f5f5;
    border-radius: 0;
}

.backgroundTh {
    border-top: 0 !important;
    padding: 16px 16px !important;
}

.backgroundTd {
    padding: 16px 16px;
    border-bottom: 1px solid #f0f0f0;
}

.logNoteIcon {
    position: absolute; 
    right: 8px; 
    top: 50%; 
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #00A09D;
    cursor: pointer;
}
.logNoteContainer {
    background-color: green; 
    width: 30px;
    height: 30px; 
    margin-top: 8px; 
    border-radius: 4px; 
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 8px;
}
