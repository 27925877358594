@import url(https://fonts.googleapis.com/css?family=Roboto:300);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.example-input .ant-input {
  width: auto;
  min-width: 50px;
  margin: 0 0 20px 20px;
}

.ant-btn-loading-icon {
  display: inline-flex !important;
  align-items: center !important;
}

.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #01579e;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,.form button:active,.form button:focus {
  background: #001628;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #001628;
  text-decoration: none;
}
.form .register-form {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #EF3B3A;
}
body {
  background: #01579e; /* fallback for old browsers */
  background: linear-gradient(to left, #001628, #001628);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}
.Table_paymentMethod__Y0oS- {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.Table_search__1QLkc {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.Table_backgroundTr__3vDs2 {
    background-color: #f5f5f5;
    border-radius: 0;
}

.Table_backgroundTh__3LaIy {
    border-top: 0 !important;
    padding: 16px 16px !important;
}

.Table_backgroundTd__1fgBe {
    padding: 16px 16px;
    border-bottom: 1px solid #f0f0f0;
    vertical-align: middle !important;
}

.Table_pagination__3GnEl {
    float: right;
}

.Table_filter-group__3vWfH {
    display: flex;
}
.OrderDetail_label__1_WDk {
    font-size: 20px;
    font-weight: bold;
    display: flex !important;
    align-items: center !important;
    /* justify-content: flex-start !important; */
}

.OrderDetail_editBuyerIcon__3sdAK {
    margin-left: 16px !important;
    cursor: pointer !important;
}

.OrderDetail_attendantName__3xz8k {
    font-size: 120%;
    font-weight: bold;
    color: #1890ff;
}

.OrderDetail_infoContainer__3WMmy {
    font-size: 16px;
    margin: 0 0 0 0;
    padding: 0 0 15px 0;
}

.OrderDetail_buyerLabel__X7Dag {
    font-weight: 600;
}

.OrderDetail_editInput__2Gw0m {
    outline: 0;
    border-width: 0 0 1px;
    border-color: #1890ff;
    margin-left: 8px;
}

.OrderDetail_orderCode__1M9Dl {
    font-size: 150%;
    font-weight: bold;
    color: #1890ff;
    margin: 0 0 15px 0;
}

.OrderDetail_backgroundTr__2_ibh {
    background-color: #f5f5f5;
    border-radius: 0;
}

.OrderDetail_backgroundTh__1qoil {
    border-top: 0 !important;
    padding: 16px 16px !important;
}

.OrderDetail_backgroundTd__-Rz90 {
    padding: 16px 16px;
    border-bottom: 1px solid #f0f0f0;
}

.OrderDetail_logNoteIcon__3hVbG {
    position: absolute; 
    right: 8px; 
    top: 50%; 
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #00A09D;
    cursor: pointer;
}
.OrderDetail_logNoteContainer__1DNnu {
    background-color: green; 
    width: 30px;
    height: 30px; 
    margin-top: 8px; 
    border-radius: 4px; 
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.OrderDetail_avatar__3nojF {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 8px;
}

