.paymentMethod {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.search {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.backgroundTr {
    background-color: #f5f5f5;
    border-radius: 0;
}

.backgroundTh {
    border-top: 0 !important;
    padding: 16px 16px !important;
}

.backgroundTd {
    padding: 16px 16px;
    border-bottom: 1px solid #f0f0f0;
    vertical-align: middle !important;
}

.pagination {
    float: right;
}

.filter-group {
    display: flex;
}